import React from "react"
import { Link } from "gatsby"
import urlSlug from "url-slug"

import "./font-awesome"

import Layout from "./layout"
import SEO from "./seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"

import Pager from "./pager"
import defaultImage from "../images/slider-01.jpg"

const newsList = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title="Novedades - ICDA" />
      <section className="mb-3">
        <div className="container">
          <div className="my-5">
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1">
                <h5 className="text-uppercase">
                  <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                  Noticias{" "}
                  <span className="font-weight-bold text-main">ICDA</span>
                </h5>
              </div>
            </div>
            <div className="row">
              {data.allStrapiNovelties.edges.map(news => (
                <div className="pb-4 col-12 col-md-6" key={news.node.id}>
                  <div
                    className="novelty-feed-img h-100"
                    style={{
                      backgroundImage: `url(${
                        news.node.imageResume?.image
                          ? news.node.imageResume.image.url
                          : defaultImage
                      }`,
                    }}
                  >
                    <div className=" p-5 text-white d-flex flex-column background h-100">
                      {/* Fecha */}
                      <h6>
                        <small>{news.node.date}</small>
                      </h6>
                      {/* Etiqueta */}
                      <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">
                        {news.node.novelties_categories.map((categories, i) => {
                          if (categories?.name) { 
                            if ((news.node.novelties_categories.length - 1) > i) {
                              return categories.name + " - "
                            } else {
                              return categories.name
                            }
                          } else {
                            return "General"
                          } 
                        })}  
                      </div>
                      <h4 className="mb-3 mt-2">{news.node.title}</h4>
                      <Link
                        to={`/noticias/${urlSlug(news.node.title)}`}
                        className="btn btn-outline-light mt-auto fit-content"
                      >
                        Ver más
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <Pager pageContext={pageContext} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default newsList
export const newsListQuery = graphql`
  query($skip: Int, $limit: Int) {
    allStrapiNovelties(
      filter: { academic_unit: { name: { eq: "ICDA" } } }
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "DD MMMM, YYYY", locale: "es")
          summary
          title
          content
          headerImages {
            image {
              url
            }
          }
          novelties_categories {
            name
          }
          imageResume {
            image {
              id
              url
            }
          }
        }
      }
    }
  }
`
